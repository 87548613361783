class CookieBotHandler {
	constructor() {

    let script1 = document.createElement('script');
    script1.id = 'Cookiebot';
    script1.src = 'https://consent.cookiebot.com/uc.js';
    script1.dataset.cbid = '568183ff-5151-463f-a2d5-2394b89b479a';
    script1.dataset.blockingmode = 'auto';
    script1.type = 'text/javascript';
    script1.dataset.culture = document.documentElement.lang;

    document.head.appendChild(script1);

		setTimeout(() => {

			const consentDeclaration = document.querySelector('#CookieDeclaration');

			if (!!consentDeclaration) {
				var script = document.createElement("script");

				// Set the source of the script to the URL of the external JavaScript file
				script.src = "https://consent.cookiebot.com/568183ff-5151-463f-a2d5-2394b89b479a/cd.js";
        script.dataset.culture = document.documentElement.lang;
				// Append the script element to the head of the document (or another appropriate location)

				document.head.appendChild(script);

      }

		}, 1000);
	}
}

export default CookieBotHandler;

