import Helper from './Helper';

class CvItem {
	constructor(cv, button, content) {
		this.cv = cv;
		this.buttons = button;
		this.content = content;

		this.clickHandler = this.e_clickHandler.bind(this);
	}

	bindEvents(){
		if(this.cv && this.buttons && this.content) {
			this.buttons.forEach(b => b.addEventListener('click', this.clickHandler));
		}
	}

	unbindEvents(){
		if(this.cv && this.buttons && this.content) {
			this.buttons.forEach(b => b.removeEventListener('click', this.clickHandler));
		}
	}

	e_clickHandler(e) {
		if (this.cv.classList.contains('open')) {
			Helper.slideClose(this.content);
			this.cv.classList.remove('open');
		} else {
			Helper.slideOpen(this.content);
			this.cv.classList.add('open');
		}
	}
}

class Cv {
  constructor(config = {}) {
    const {} = config;
    this.cvs = Array.prototype.slice.call(document.querySelectorAll('.lc-cv'));
		this.cvItems = [];

    if (this.cvs && this.cvs.length !== 0) {
      this.bindEvents();
    }
  }

  bindEvents() {
    this.cvs.forEach(el => {
			const buttons = el.querySelectorAll('.lc-cv__button-container, .lc-cv__header-close');
      const content = el.querySelector('.lc-cv__content');
			this.cvItems.push(new CvItem(el, buttons, content));
    });

		this.cvItems.forEach(c => c.bindEvents());
  }

	unbindEvents() {
		this.cvItems.forEach(c => c.unbindEvents());
		this.cvItems = [];

		this.cvs = Array.prototype.slice.call(document.querySelectorAll('.lc-cv'));
	}
}

export default Cv;
