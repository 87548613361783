import Accordion from "./Classes/Accordion";
import Swiper from "swiper";
import  {Navigation, Pagination} from "swiper/modules";
import ResponsiveTablesLight from "./Classes/ResponsiveTablesLight";
import CV from "./Classes/Cv";
import Video from "./Classes/Video";
import CountUpper from "./Classes/Countupper";
import CookieBotHandler from "./Classes/CookieBotHandler";


window.addEventListener("page_event_load", () => {
  new Accordion({
    accordions: '.lc-accordion__inner-head',
    pxCorrectionMobile: 110,
    pxCorrectionDesktop: 105,
    mobileBreakpoint: 1025
  });

  new CookieBotHandler();
  new CV();

  new Video();
  new CountUpper();


  new Swiper('.swiper', {
    modules: [Navigation, Pagination],
    loop: true,
    autoplay: {
      delay: 2500,
      disableOnInteraction: true,
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  });

  new ResponsiveTablesLight({
    tableComponentSelector: '.lc-component--table',
    tableContainerSelector: '.ns-table-container',
    tableSelector: '.lc-component--table table'
  });
});

window.addEventListener('note_event_load', evt => {
  // console.log("note loaded event");
  document.body.classList.add("note-open");

  new ResponsiveTablesLight({
    tableComponentSelector: '.lc-component--table',
    tableContainerSelector: '.ns-table-container',
    tableSelector: '.lc-component--table table'
  });

});


window.addEventListener('note_unloaded', evt => {
  //console.log("note unloaded event");
  document.body.classList.remove("note-open");
});
