import { CountUp } from 'countup.js';
import Helper from './Helper.js';

class CountUpper {
  constructor() {
    this.keyfigures = document.querySelectorAll('.lc-keyfigure__number');
    console.log(this.keyfigures);
    this.classIn = 'is-in-viewport';
    this.classOut = 'is-not-in-viewport';

    // Determine the decimal and thousands separators based on the locale


    const decimalSeparator = document.documentElement.lang === 'en' ? '.' : '.';
    const thousandSeparator = document.documentElement.lang === 'en' ? ',' : '’';

    this.options = {
      useGrouping: true, // Enable grouping (thousands separators)
      decimal: decimalSeparator,
      separator: thousandSeparator
    };

    this.numberObj = this.addNumbersToArray(this.keyfigures);

    window.scrollTo(window.scrollX, window.scrollY - 1);
    window.scrollTo(window.scrollX, window.scrollY + 1);


    window.addEventListener("scroll", e => {
      this.keyfigures.forEach((item, index) => {
        this.addClassOnScroll(item, index, this.classIn, this.classOut);
        if (Helper.hasClass(item, this.classIn) && !Helper.hasClass(item, 'counting')) {
          Helper.addClass(item, 'counting');
          this.options.decimalPlaces = this.getDecimalPlaces(item.innerText);

          const countUp = new CountUp(item, this.numberObj[index], this.options);
          countUp.start();
        }
      });
    });
  }

  parseFormattedNumber(formattedNumber) {
    // Replace the locale-specific separators before parsing
    const regex = new RegExp('\\' + this.options.separator, 'g');
    console.log(formattedNumber);
    formattedNumber = formattedNumber.replace(regex, '').replace(this.options.decimal, '.');
    console.log(formattedNumber);
    return parseFloat(formattedNumber);
  }

  addNumbersToArray(keyfigures) {
    return Array.from(keyfigures).map(item => this.parseFormattedNumber(item.innerText));
  }

  addClassOnScroll(element, index, classIn, classOut) {
    if (Helper.isInViewportKeyfigure(element)) {
      Helper.addClass(element, classIn);
      Helper.removeClass(element, classOut);
    } else {
      Helper.removeClass(element, classIn);
      Helper.addClass(element, classOut);
    }
  }

  getDecimalPlaces(numberStr) {
    const parts = numberStr.split(this.options.decimal);
    return parts.length > 1 ? parts[1].length : 0;
  }
}

export default CountUpper;
